/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/layout/Layout';
import SEO from '../components/layout/SEO';
import Spinner from '../components/spinner/Spinner';
import Tick from '../components/tick/Tick';

import * as styles from '../css/pages/EmailVerification.module.scss';

const EmailVerification = ({ location }) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const params = new URLSearchParams(location.search);
    const code = params.get('code');

    const appStore = '../images/emailVerification/app-store.png';
    const googlePlay = '../images/emailVerification/google-play.png';

    useEffect(() => {
        (async () => {
            const res = await fetch(
                `https://app.roundups.org/api/roundupusers/v1/verify-email/?code=${encodeURIComponent(
                    code,
                )}`,
            );
            const resJson = await res.json();
            if (resJson.status === 'SUCCESS') {
                setLoading(false);
            } else {
                setError('Something went wrong');
            }
        })();
    }, []);

    return (
        <>
            <SEO
                title="Email verification success"
                path="/email-verification/"
                allowIndexing={false}
            />
            <Layout>
                <section className={styles.EmailVerification}>
                    {loading ? (
                        <>
                            <h1>Verifying email...</h1>
                            <div className={styles.spinnerContainer}>
                                <Spinner />
                            </div>
                        </>
                    ) : (
                        <>
                            <h1>Email verified successfully!</h1>
                            <div className={styles.tickContainer}>
                                <Tick />
                            </div>
                            <div className={styles.container}>
                                <p style={{ textAlign: 'center' }}>
                                    Continue setting up your account inside the
                                    Ripples app
                                    <br />
                                </p>
                                <a
                                    href="https://apps.apple.com/gb/app/roundups/id1534594188"
                                    className={styles.imgContainer}>
                                    <StaticImage
                                        className={styles.appStore}
                                        src={appStore}
                                        alt="Download Ripples on the app store"
                                    />
                                </a>
                                <a
                                    href="https://play.google.com/store/apps/details?id=org.roundups.roundups"
                                    className={styles.imgContainer}>
                                    <StaticImage
                                        className={styles.googlePlay}
                                        src={googlePlay}
                                        alt="Download Ripples on Google Play"
                                    />
                                </a>
                            </div>
                        </>
                    )}
                </section>
            </Layout>
        </>
    );
};

export default EmailVerification;
